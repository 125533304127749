const Routes = {
  SIGN_IN: '/sign-in',
  FORGOT_PASSWORD: '/forgot-password',
  ONBOARDING: '/onboarding',
  INVITE: '/invite',
  CLIENT_DETAILS: '/client-details',
  DASHBOARD: '/dashboard',
  EMPLOYEES: '/employees',
  CONFIRMATION: '/onboarding/confirmation',
};

export default Routes;
