import firebaseSwr from 'utils/firebase-swr';
import routes from 'app/Routes';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

function Home() {
  const router = useRouter();
  const authUser = firebaseSwr.auth.currentUser;

  useEffect(() => {
    const fetch = async () => {
      if (authUser) {
        try {
          const { claims } = await authUser.getIdTokenResult();
          if (claims.employee) {
            router.push(routes.DASHBOARD);
          } else {
            router.push(`${routes.ONBOARDING}/0/step/0`);
          }
        } catch (e) {
          router.push(routes.SIGN_IN);
          throw e;
        }
      } else {
        router.push(routes.SIGN_IN);
        router.push(
          authUser ? `${routes.ONBOARDING}/0/step/0` : routes.SIGN_IN
        );
      }
    };

    fetch();
  });

  return null;
}

export default Home;
